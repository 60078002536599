<template>
  <!-- star -->

  <div class="kejipage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerImg="Img1"></banner2>
    <!-- 面包屑 -->
    <div class="Crumbs">
      <div style="font-size: 20px;">当前位置: 产品介绍><span>c5</span></div>
    </div>

    <!-- 教育 -->
    <div class="Education">
      <div class="Education_cont" style="">
        <!-- <div>c5模块</div> -->
        <div class="Education_item">
          <div class="Education_item1" style="margin-top: -70px;">
            <!-- <img v-lazy="require('../assets/img/img1.jpeg')" alt="" style="height:340px" lazy/> -->
            <img v-lazy="require('../assets/img/product/c51.png')" alt="" style="height:340px" lazy />
            <div class="Education_item_text">
              <p>FM-C5(国内小潮版) 详情（Product details）</p>
              <span>
                简介：车辆FM-C5
                ，车体颜色绚丽，蓝紫配色，潮流时尚，具有辨识度，更能够减少安全隐患，深受年轻人的喜爱。车体采用铝合金立杆和铁地板，防火阻燃，前后双减震系统，配备350W电机，车速可调节，配备强大刹车系统，前轮鼓刹，后轮电刹，即停即走，10英寸免维修橡胶轮胎，
                无论是户外游玩还是城市通勤，妥妥够。优质锂电池，提供长达40km的续航里程，安全便捷出行。
              </span>
            </div>
          </div>
          <div class="Education_item1">
            <div class="Education_item_text">
              <p>FM-C5(国内小潮版) ，全方位性能提升
                产品介绍：</p>
              <span>
                超长续航大电池：FM-C5使用36V14.5AH大容量优质鲤离子电池，持续的高倍率电流输出能力，提供40km超长里程
                <br>
                超大功率电机：FM-C5使用的是350W电机，最大爬坡度可达15°，能适应更多复杂，更颠簸的路况。
                <br>
                超高颜值车身：FM-C5独特的外观标识，颜色靓丽，为滑板车注入了新的活力，深受年轻人的喜爱。
                <br>
                灯光系统：FM-C5采用了LED灯，前面除了主大灯，后面尾翼还有警示灯，能满足您的夜间行驶，您夜间行驶更加安全。
              </span>
              <!-- <span>依据院校专业方向，提供网络、安全、云计算、大数据、人工智能等全系列ICT实训综合解决方案，教学实训平台的设计全面落实“产、学、用、监、评”
                一体化的思想和模式，从教学、实践、使用、监控、评估等多方面注重专业人才和特色人才的培养。学生可以通过在教学平台的学习熟练掌握ICT实训综合解决方案的基础知识，
                并能运用掌握的知识在课程实验中进行动手实践。</span> -->
            </div>
            <img v-lazy="require('../assets/img/product/c52.png')" alt="" style="width:386px;height: 340px;" lazy />
            <!-- <img v-lazy="require('../assets/img/640.jpeg')" alt="" style="width:386px;height: 340px;" lazy/> -->
          </div>
        </div>
      </div>
    </div>

    <footer2></footer2>

    <!-- end -->
  </div>
</template>


<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav2.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
  },
  data () {
    return {
      h1: "公司产品",
      Img1: require("../assets/img/banner1.png"),
      // arrList: [
      //   {
      //     url: require("../assets/img/C5/人脸识别.png"),
      //     p: "水水水水搜索",
      //     span: "人脸识别班牌是当今校园文化建设、数字化建设的系统之一。学校为每个教室配置一个前端班牌一体机，一般安装在教室门口或教室内，用来显示班级信息、当前课程信息、班级活动信息以及学校的通知信息。",
      //   },
      //   {
      //     url: require("../assets/img/C5/人脸识别.png"),
      //     p: "水水水水搜索",
      //     span: "人脸识别班牌是当今校园文化建设、数字化建设的系统之一。学校为每个教室配置一个前端班牌一体机，一般安装在教室门口或教室内，用来显示班级信息、当前课程信息、班级活动信息以及学校的通知信息。",
      //   },
      //   {
      //     url: require("../assets/img/C5/人脸识别.png"),
      //     p: "水水水水搜索打打",
      //     span: "人脸识别班牌是当今校园文化建设、数字化建设的系统之一。学校为每个教室配置一个前端班牌一体机，一般安装在教室门口或教室内，用来显示班级信息、当前课程信息、班级活动信息以及学校的通知信息。",
      //   },
      // ],
    };
  },
  methods: {},
};
</script>
<style scoped>
@charset "utf-8";

/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}

/*去内外边距*/
html,
body {
  font-family: '微软雅黑', '宋体', Arail, Tabhoma;
  text-align: left;
}

/*字体 文本居左*/
ul {
  list-style: none;
}

/*去ul li 小点*/
img {
  border: 0 none;
}

/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}

/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}

/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}

/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}

/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
}

/*清浮动*/
a {
  color: #000000;
}

/*==========================Reset_End===========================*/

.kejipage_wrap {
  width: 100%;
  /* width: 1920px; */
  margin: 0 auto;
  height: 1000px;
}

/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}

.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}

.Crumbs div {
  line-height: 46px;
}

.Crumbs span {
  color: #000000;
}

/* 科技板块 */
/* .TechnologySector_cont {
  width: 1200px;
  height: 810px;
  margin: 0 auto;
  padding-top: 64px;
} */
.TechnologySector_cont h1 {
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 45px;
  text-align: center;
}

.TechnologySector_cont_bottom img {
  margin-top: 45px;
  float: right;
  width: 1000px;
  height: 520px;
}

.TechnologySector_cont_bottom_text {
  /* margin-top: -424px; */
  float: left;
  width: 720px;
  height: 504px;
  background: #f8f8f8;
  z-index: 10;
}

.TechnologySector_cont_bottom_text div {
  margin-top: 36px;

  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 32px;
}

.TechnologySector_cont_bottom_text p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 35px;
  margin-top: 24px;
}

.TechnologySector_cont_bottom_text_english {
  font-size: 70px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f8f8f8;
  line-height: 98px;
}

/* 教育 */
.Education {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  /* width: 1920px; */
  height: 1041px;
  /* height: 1741px; */
  /* background: #f8f8f8; */
}

.Education_cont {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid orange; */
  width: 1200px;
  margin: 0 auto;
  margin-top: -200px;
  padding-top: 91px;
}

.Education_cont h1 {
  text-align: center;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 45px;
}

.Education_item {
  margin-top: 45px;
}

.Education_item1 {
  display: flex;
}

.Education_item_text {
  background: #ffffff;
  width: 740px;
  /* height: 340px; */
}

.Education_item_text p {
  margin-top: 62px;
  margin-left: 86px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: red;
  line-height: 33px;
}

.Education_item_text span {
  width: 568px;
  height: 155px;
  margin-top: 24px;
  margin-left: 86px;

  display: inline-block;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 28px;
}

.Education_item1 {
  margin-bottom: 40px;
}

.Education_item1_block {
  width: 550px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 86px;
}

.Education_item1_block .div3 {
  margin: 40px 24px 38px 0;
  line-height: 40px;
  text-align: center;
  width: 172px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
}

.Education_item1_block .div4 {
  margin-top: -10px;
}

.Education_item1_block .div5 {
  margin-top: -10px;
}

.Education_item1_block div {
  margin: 40px 24px 38px 0;
  line-height: 40px;

  text-align: center;
  width: 140px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
}

/* 城市 */
.Citi {
  /* width: 1920px; */
  width: 100%;
  height: 883px;
  background-image: url('../assets/img/蒙版.png');
}

.Citi_cont {
  width: 1200px;
  margin: 0 auto;
}

.Citi_cont > h1 {
  text-align: center;
  margin-top: 83px;

  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 45px;
}

.Citi_cont_p {
  text-align: center;
  margin-top: 40px;

  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 32px;
}

.Citi_cont_item {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin-top: 64px;
}

.Citi_cont_item1 {
  width: 304px;
}

.Citi_cont_item1 p {
  margin: 24px 0;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 25px;
}

.Citi_cont_item1 span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}

/* 华为 */
.HuaWei {
  width: 100%;
  /* width: 1920px; */
  height: 809px;
  background: #ffffff;
}

.HuaWei_cont {
  padding-top: 80px;
  width: 1200px;
  margin: 0 auto;
}

.HuaWei_cont > h1 {
  text-align: center;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 45px;
}

.HuaWei_cont_p {
  margin-top: 60px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 22px;
}

.HuaWei_cont_item {
  display: flex;
  margin-top: 81px;
  margin-left: 150px;
}

.HuaWei_cont_item_img img {
  text-align: center;
  width: 320px;
  height: 326px;
}

.HuaWei_cont_item_l1 {
  text-align: right;

  margin-right: 41px;
}

.HuaWei_cont_item_l1 p {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 33px;
  margin-left: 180px;
}

.HuaWei_cont_item_l1 span {
  float: right;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}

.HuaWei_cont_item_l2 {
  text-align: right;
  margin-right: 41px;

  margin-top: 90px;
}

.HuaWei_cont_item_l2 p {
  margin-left: 180px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 33px;
  margin-left: 180px;
}

.HuaWei_cont_item_l2 span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}

.HuaWei_cont_item_r {
  margin-left: 16px;
}

.HuaWei_cont_item_r1 p {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 33px;
}

.HuaWei_cont_item_r1 span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}

.HuaWei_cont_item_r2 {
  margin-top: 90px;
}

.HuaWei_cont_item_r2 p {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 33px;
}

.HuaWei_cont_item_r2 span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}

.HuaWei_cont_item_c {
  margin-top: 41px;
  text-align: center;
}

.HuaWei_cont_item_c p {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 33px;
}

.HuaWei_cont_item_c span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}
</style>


